<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10">
      <!--begin::Logo-->
      <!-- <a href="#" class="mb-12">
        <img alt="Logo" src="media/logos/logo-2-dark.svg" class="h-45px" />
      </a>-->
      <a href="#" class="mb-6">
        <h1 class="logo display-5 text-primary">Bauwise</h1>
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          novalidate="novalidate"
          @submit="handleSubmit"
          id="kt_login_signup_form"
          :validation-schema="registration"
        >
          <!--begin::Heading-->
          <div class="mb-10 text-center">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">{{ translate('signUpPage.createAnAccount') }}</h1>
            <!--end::Title-->

            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4" v-if="!$props.token">
              {{ translate('signUpPage.alreadyHaveAnAccount') }}
              <router-link
                to="/sign-in"
                class="link-primary fw-bolder"
              >{{ translate('signUpPage.signInHere') }}</router-link>
            </div>
            <!--end::Link-->
          </div>
          <!--end::Heading-->

          <!--begin::Action-->
          <!-- <button type="button" class="btn btn-light-primary fw-bolder w-100 mb-10">
            <img alt="Logo" src="media/svg/brand-logos/google-icon.svg" class="h-20px me-3" />
            Sign in with Google
          </button>-->
          <!--end::Action-->

          <!--begin::Separator-->
          <div class="d-flex align-items-center mb-10" v-if="!$props.token">
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
            <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
          </div>
          <!--end::Separator-->

          <!--begin::General User Info Input group-->
          <div class="row fv-row mb-7">
            <!--begin::First Name Col-->
            <div class="col-xl-6">
              <label
                class="form-label fw-bolder text-dark fs-6"
                for="firstName"
              >{{ translate('signUpPage.firstName') }}</label>
              <Field
                id="firstName"
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="name"
                autocomplete="off"
                tabindex="1"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" v-slot="{ message }">
                    <p>{{ translate(`${message}`) }}</p>
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <!--end::First Name Col-->

            <!--begin::Last Name Col-->
            <div class="col-xl-6">
              <label
                class="form-label fw-bolder text-dark fs-6"
                for="lastName"
              >{{ translate('signUpPage.lastName') }}</label>
              <Field
                id="lastName"
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="surname"
                autocomplete="off"
                tabindex="2"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="surname" v-slot="{ message }">
                    <p>{{ translate(`${message}`) }}</p>
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <!--end::Last Name Col-->
          </div>
          <!--end::General User Info Input group-->

          <!--begin::Company Info Input Group-->
          <div class="fv-row mb-7" v-if="!$props.token">
            <label
              class="form-label fw-bolder text-dark fs-6"
              for="companyName"
            >{{ translate('signUpPage.company') }}</label>
            <Field
              id="companyName"
              class="form-control form-control-lg form-control-solid"
              type="text"
              name="companyName"
              autocomplete="off"
              tabindex="3"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="companyName" v-slot="{ message }">
                  <p>{{ translate(`${message}`) }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <!--end::Company Info Input Group-->

          <!--begin::Email Input group-->
          <div class="fv-row mb-7" v-if="!$props.token">
            <label
              class="form-label fw-bolder text-dark fs-6"
              for="email"
            >{{ translate('signUpPage.email') }}</label>
            <Field
              id="email"
              class="form-control form-control-lg form-control-solid"
              type="email"
              placeholder="name@example.com"
              name="email"
              autocomplete="off"
              tabindex="3"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" v-slot="{ message }">
                  <p>{{ translate(`${message}`) }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div class="fv-row mb-7" v-else>
            <label
              class="form-label fw-bolder text-dark fs-6"
              for="email"
            >{{ translate('signUpPage.email') }}</label>
            <input
              id="email"
              class="form-control form-control-lg form-control-solid"
              type="email"
              :placeholder="tempEmail"
              :value="tempEmail"
              name="email"
              autocomplete="off"
              tabindex="3"
              disabled
              aria-label="email"
            />
          </div>
          <!--end::Email Input group-->

          <!--begin::Password Input group-->
          <div class="mb-10 fv-row" data-kt-password-meter="true">
            <!--begin::Wrapper-->
            <div class="mb-1">
              <!--begin::Label-->
              <div class="d-flex justify-content-between align-items-end">
                <label
                  class="form-label fw-bolder text-dark fs-6"
                  for="newPassword"
                >{{ $props.token ? `${translate('signUpPage.password')}` : `${translate('signUpPage.password')}` }}</label>

                <button
                  type="button"
                  class="btn btn-color-primary"
                  id="kt_test"
                  @click="showPassword = !showPassword"
                >
                  <i class="far fs-2x" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                  {{ showPassword ? `${translate('signUpPage.hide')}` : `${translate('signUpPage.show')}` }}
                </button>
              </div>

              <!--end::Label-->

              <!--begin::Input wrapper-->
              <div class="position-relative mb-3">
                <Field
                  id="newPassword"
                  class="form-control form-control-lg form-control-solid"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  autocomplete="new-password"
                  tabindex="4"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" v-slot="{ message }">
                      <p>{{ translate(`${message}`) }}</p>
                    </ErrorMessage>
                  </div>
                </div>
                <label class="form-label text-dark fs-7">{{ translate('signUpPage.passwordRule') }}</label>
              </div>
              <!--end::Input wrapper-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Password Input group--->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <label class="form-check form-check-custom form-check-solid" for="tocCheckbox">
              <Field
                id="tocCheckbox"
                class="form-check-input border border-dark"
                type="checkbox"
                name="toc"
                value="1"
                tabindex="5"
              />
              <span class="form-check-label fw-bold text-gray-700 fs-6">
                {{ translate('signUpPage.iAcceptThe') }}
                <a
                  href="https://www.bauwise.com/terms-conditions/"
                  class="ms-1 link-primary"
                  target="_blank"
                >{{ translate('signUpPage.termsCondition') }}</a>.
              </span>
            </label>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="toc" v-slot="{ message }">
                  <p>{{ translate(`${message}`) }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <p>
            This site is protected by reCAPTCHA and the Google
            <a
              href="https://policies.google.com/privacy"
            >Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>

          <!--begin::Actions-->
          <div class="text-center">
            <button
              id="kt_sign_up_submit"
              ref="submitButton"
              type="submit"
              class="btn btn-lg btn-primary w-100 mb-5"
              :disabled="loading"
              tabindex="6"
            >
              <span class="indicator-label">{{ translate('signUpPage.continue') }}</span>
              <span class="indicator-progress">
                {{ translate('signUpPage.pleaseWait') }}...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
        <div class="d-flex align-items-center p-10 pb-0">
          <Multiselect
            class="m-0 me-5 custom__multiselect"
            tabindex="6"
            v-bind="languageDropdown"
            v-model="languageDropdown.value"
            @change="handleLocaleChanges"
          >
            <template v-slot:caret>
              <div class="multiselect-caret custom__caret"></div>
            </template>
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="rounded-1 multiselect-label-icon" :src="value.icon" />
                {{ value.label }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="rounded-1 multiselect-option-icon" :src="option.icon" />
              {{ option.label }}
            </template>
          </Multiselect>
        </div>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { translate } from "@/core/helpers/config";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import Multiselect from '@vueform/multiselect';
import { useReCaptcha } from 'vue-recaptcha-v3'

enum Language {
  en = 'en',
  et = 'ee',
  au = 'au',
  de = 'de',
  ru = 'ru',
  lt = 'lt',
  uk = 'uk'
}

export default defineComponent({
  name: "sign-up",
  props: ['token', 'email'],
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect,
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const showPassword = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const reCaptcha = useReCaptcha();
    const loading = ref<boolean>(false);
    const selectedLang = ref('et');
    const languageDropdown = reactive({
      mode: "single",
      caret: true,
      closeOnSelect: true,
      canDeselect: false,
      canClear: false,
      object: false,
      value: 'ee',
      options: [
        {
          value: 'en',
          label: "English",
          icon: "media/flags/united-states.svg"
        },
        {
          value: 'ee',
          label: "Eesti",
          icon: "media/flags/estonia.svg"
        },
        {
          value: 'de',
          label: "Deutsch",
          icon: "media/flags/germany.svg"
        },
        {
          value: 'au',
          label: "Australia",
          icon: "media/flags/australia.svg"
        },
        {
          value: 'lt',
          label: "Lietuvių",
          icon: "media/flags/lithuania.svg"
        },
        {
          value: 'ru',
          label: "Pусский",
          icon: "media/flags/russia.svg"

        },
        {
          value: 'uk',
          label: "Yкраїнська",
          icon: "media/flags/ukraine.svg"
        },
      ],
      trackBy: "value",
      label: "label",
    });

    const recaptcha = async () => {
      await reCaptcha!.recaptchaLoaded();

      const token = await reCaptcha!.executeRecaptcha('submit');

      const userVerified = await store.dispatch(Actions.RECAPTCHA_VERIFY, token);
      return userVerified
    }

    const tempEmail = ref(props.email || localStorage.getItem('tempEmail'))

    const registration = Yup.object().shape({
      name:
        Yup
          .string()
          .required('signUpPage.firstNameRequired'),
      surname:
        Yup
          .string()
          .required('signUpPage.lastNameRequired'),
      companyName: !props.token || props.token === "" ?
        Yup
          .string()
          .required('signUpPage.companyNameRequired') :
        Yup.string(),
      email: !props.token || props.token === "" ?
        Yup
          .string()
          .email()
          .min(4, 'signUpPage.emailMin4')
          .required('signUpPage.emailRequired') :
        Yup.string().min(4),
      password:
        Yup
          .string()
          .min(6, 'signUpPage.passwordMin6')
          .required('signUpPage.passwordRequired'),
      toc: Yup.boolean()
        .oneOf([true], 'signUpPage.termsConditionRequire')
        .required('signUpPage.termsConditionRequire'),
    });

    const onSubmitRegisterContract = (values) => {
      const payload = {
        ...values,
        token: props.token
      }

      loading.value = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      store
        .dispatch(Actions.REGISTER_CONTRACTOR, payload)
        .then(() => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          localStorage.removeItem('tempEmail')
          // Go to page after successfully login
          router.replace(`/${route.query.redirect || '/contract'}`)
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");

          });
        });
    }

    const onSubmitRegisterUser = (values) => {
      loading.value = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      store
        .dispatch(Actions.REGISTER, values)
        .then(() => {
          Swal.fire({
            text: `Thank you for registering with us. Don't forget to verify your email.`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
            // Go to page after successfully login
            // router.push({ name: "verify-email" });
          });
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");

          });
        });

    };

    const handleSubmit = async (values) => {
      // const { data } = await recaptcha()

      // if ((!data.success || data.score <= 0.5) && process.env.VUE_APP_ENABLE_RECAPTCHA) return;

      if (props.token) {
        onSubmitRegisterContract(values)
      } else {
        onSubmitRegisterUser(values)
      }
    }

    const handleLocaleChanges = (value) => {
      i18n.locale.value = value
      localStorage.setItem('lang', value)
    }

    onMounted(() => {
      let language;

      if (localStorage.getItem('lang') === 'undefined' || !localStorage.getItem('lang')) {
        language = window.navigator['userLanguage'] || window.navigator.language;
      } else {
        language = localStorage.getItem('lang')
      }

      const locale = language.toLowerCase();
      languageDropdown.value = locale === 'en-au' ? 'au' : Language[locale.split("-")[0]] || 'ee'
      handleLocaleChanges(languageDropdown.value)
    })

    return {
      registration,
      onSubmitRegisterUser,
      onSubmitRegisterContract,
      showPassword,
      submitButton,
      loading,
      tempEmail,
      handleSubmit,
      translate,
      selectedLang,
      languageDropdown,
      handleLocaleChanges,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/sass/style";

.radio-input {
  margin-right: 10px !important;
}
</style>