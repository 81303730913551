
import { defineComponent, ref, watchEffect, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { translate } from "@/core/helpers/config";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import Multiselect from '@vueform/multiselect';
import { useReCaptcha } from 'vue-recaptcha-v3'

enum Language {
  en = 'en',
  et = 'ee',
  au = 'au',
  de = 'de',
  ru = 'ru',
  lt = 'lt',
  uk = 'uk'
}

export default defineComponent({
  name: "sign-up",
  props: ['token', 'email'],
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect,
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const showPassword = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const reCaptcha = useReCaptcha();
    const loading = ref<boolean>(false);
    const selectedLang = ref('et');
    const languageDropdown = reactive({
      mode: "single",
      caret: true,
      closeOnSelect: true,
      canDeselect: false,
      canClear: false,
      object: false,
      value: 'ee',
      options: [
        {
          value: 'en',
          label: "English",
          icon: "media/flags/united-states.svg"
        },
        {
          value: 'ee',
          label: "Eesti",
          icon: "media/flags/estonia.svg"
        },
        {
          value: 'de',
          label: "Deutsch",
          icon: "media/flags/germany.svg"
        },
        {
          value: 'au',
          label: "Australia",
          icon: "media/flags/australia.svg"
        },
        {
          value: 'lt',
          label: "Lietuvių",
          icon: "media/flags/lithuania.svg"
        },
        {
          value: 'ru',
          label: "Pусский",
          icon: "media/flags/russia.svg"

        },
        {
          value: 'uk',
          label: "Yкраїнська",
          icon: "media/flags/ukraine.svg"
        },
      ],
      trackBy: "value",
      label: "label",
    });

    const recaptcha = async () => {
      await reCaptcha!.recaptchaLoaded();

      const token = await reCaptcha!.executeRecaptcha('submit');

      const userVerified = await store.dispatch(Actions.RECAPTCHA_VERIFY, token);
      return userVerified
    }

    const tempEmail = ref(props.email || localStorage.getItem('tempEmail'))

    const registration = Yup.object().shape({
      name:
        Yup
          .string()
          .required('signUpPage.firstNameRequired'),
      surname:
        Yup
          .string()
          .required('signUpPage.lastNameRequired'),
      companyName: !props.token || props.token === "" ?
        Yup
          .string()
          .required('signUpPage.companyNameRequired') :
        Yup.string(),
      email: !props.token || props.token === "" ?
        Yup
          .string()
          .email()
          .min(4, 'signUpPage.emailMin4')
          .required('signUpPage.emailRequired') :
        Yup.string().min(4),
      password:
        Yup
          .string()
          .min(6, 'signUpPage.passwordMin6')
          .required('signUpPage.passwordRequired'),
      toc: Yup.boolean()
        .oneOf([true], 'signUpPage.termsConditionRequire')
        .required('signUpPage.termsConditionRequire'),
    });

    const onSubmitRegisterContract = (values) => {
      const payload = {
        ...values,
        token: props.token
      }

      loading.value = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      store
        .dispatch(Actions.REGISTER_CONTRACTOR, payload)
        .then(() => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          localStorage.removeItem('tempEmail')
          // Go to page after successfully login
          router.replace(`/${route.query.redirect || '/contract'}`)
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");

          });
        });
    }

    const onSubmitRegisterUser = (values) => {
      loading.value = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      store
        .dispatch(Actions.REGISTER, values)
        .then(() => {
          Swal.fire({
            text: `Thank you for registering with us. Don't forget to verify your email.`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
            // Go to page after successfully login
            // router.push({ name: "verify-email" });
          });
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");

          });
        });

    };

    const handleSubmit = async (values) => {
      // const { data } = await recaptcha()

      // if ((!data.success || data.score <= 0.5) && process.env.VUE_APP_ENABLE_RECAPTCHA) return;

      if (props.token) {
        onSubmitRegisterContract(values)
      } else {
        onSubmitRegisterUser(values)
      }
    }

    const handleLocaleChanges = (value) => {
      i18n.locale.value = value
      localStorage.setItem('lang', value)
    }

    onMounted(() => {
      let language;

      if (localStorage.getItem('lang') === 'undefined' || !localStorage.getItem('lang')) {
        language = window.navigator['userLanguage'] || window.navigator.language;
      } else {
        language = localStorage.getItem('lang')
      }

      const locale = language.toLowerCase();
      languageDropdown.value = locale === 'en-au' ? 'au' : Language[locale.split("-")[0]] || 'ee'
      handleLocaleChanges(languageDropdown.value)
    })

    return {
      registration,
      onSubmitRegisterUser,
      onSubmitRegisterContract,
      showPassword,
      submitButton,
      loading,
      tempEmail,
      handleSubmit,
      translate,
      selectedLang,
      languageDropdown,
      handleLocaleChanges,
    };
  },
});
